export const EVENTS_PATH = '/events';
export const JOBS_PATH = '/jobs';
export const JOBS_SEARCH_PATH = `${JOBS_PATH}/search`;
export const LOGIN_PATH = '/login';
export const NEWS_PATH = '/news';
export const NEWS_SEARCH_PATH = `${NEWS_PATH}/search`;
export const ORGANIZATIONS_PATH = '/organizations';
export const ORGANIZATIONS_SEARCH_PATH = `${ORGANIZATIONS_PATH}/search`;
export const RECRUITER_PATH = '/recruiter';
export const RECRUITER_SEARCH_PATH = `${RECRUITER_PATH}/search`;
export const RESET_PASSWORD_PATH = '/reset_password';
